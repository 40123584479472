// Generated by Framer (cec372b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PK6lwKZub"];

const variantClassNames = {PK6lwKZub: "framer-v-w1n8oo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; text?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "PK6lwKZub", text: Tc7HaGetO = "Our approach increases the total customer base in the region.", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PK6lwKZub", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-gdOOX", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-w1n8oo", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PK6lwKZub"} ref={ref} style={{...style}} transition={transition}><Text __fromCanvasComponent alignment={"left"} className={"framer-iafpk"} data-framer-name={"Our approach increases the total customer base in the region."} fonts={["CUSTOM;Altone Light"]} layoutDependency={layoutDependency} layoutId={"YwK5wQ2Fa"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Our approach increases the total customer base in the region.</span><br></span></span>"} style={{"--framer-font-family": "\"Altone Light\", serif", "--framer-font-size": "18.03px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0px", "--framer-line-height": "28.85px", "--framer-text-alignment": "left", "--framer-text-color": "rgb(32, 23, 71)", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={Tc7HaGetO} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-gdOOX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gdOOX * { box-sizing: border-box; }", ".framer-gdOOX .framer-17kyb47 { display: block; }", ".framer-gdOOX .framer-w1n8oo { height: 87px; overflow: visible; position: relative; width: 237px; }", ".framer-gdOOX .framer-iafpk { bottom: 0px; flex: none; height: auto; overflow: hidden; position: absolute; right: 0px; white-space: pre-wrap; width: 237px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 87
 * @framerIntrinsicWidth 237
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Tc7HaGetO":"text"}
 */
const Framerr1Y1Z5CKt: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerr1Y1Z5CKt;

Framerr1Y1Z5CKt.displayName = "Archive / paragraph";

Framerr1Y1Z5CKt.defaultProps = {height: 87, width: 237};

addPropertyControls(Framerr1Y1Z5CKt, {Tc7HaGetO: {defaultValue: "Our approach increases the total customer base in the region.", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(Framerr1Y1Z5CKt, [{family: "Altone Light", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/r1Y1Z5CKt:default", url: "assets/o9vU2jZnRxM9i4gs3Mg5ocHJwV4.ttf"}, url: new URL("assets/o9vU2jZnRxM9i4gs3Mg5ocHJwV4.ttf", import.meta.url).href}])